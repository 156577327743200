import * as React from "react"
import { Link, graphql } from "gatsby"

import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Box from "../components/box"
import Text from "../components/text"

const WritingsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `all writings`
  const posts = data.allMdx.nodes

  if (posts.length === 0) {
    return (
      <Layout title={siteTitle}>
        <Seo title="all writings" />
        <Footer />
        <p>nothing's here</p>
      </Layout>
    )
  }

  return (
    <Layout title={siteTitle}>
      <Seo title="all writings" />
      <Box as="section" css={{ paddingTop: "30vh" }}>
        <Text as="h1" css={{ marginBottom: "$32", fontSize: "$14" }}>
          all writings
        </Text>

        <ol style={{ listStyle: `none`, paddingLeft: "0" }}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <Text
                    as="h2"
                    itemProp="headline"
                    css={{
                      marginBottom: "$4",
                      fontSize: "$base",
                      textDecoration: "none",
                    }}
                  >
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </Text>
                  <small>{post.frontmatter.date}</small>
                </article>
              </li>
            )
          })}
        </ol>
      </Box>
    </Layout>
  )
}

export default WritingsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
